<template>
	<v-row style="height: 102vh; padding-top: 100px">
		<v-col cols="12" class="text-center">
			<h1 style="font-weight: normal; font-size: 100px;">welcome</h1>
			<h1 style="font-weight: normal; font-size: 50px;">to</h1>
			<h1 style="font-weight: normal; font-size: 100px;">rewind</h1>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "Splash"
}
</script>

<style scoped>

</style>